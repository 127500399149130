<template>
    <div class="searchBox">
        <div>
            <!-- 请输入红包名称 -->
            <el-input class="form_item" v-model="params_search.name" :clearable='true' :placeholder="$t('RedName')" size='small' style="max-width:240px"></el-input>
            <!-- 请选择红包状态 -->
            <el-select  class="form_item" v-model="params_search.status" :clearable='true' :placeholder="$t('Status_red')" size='small' style="max-width:240px">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <!-- 开始 结束 时间 -->
            <el-date-picker
                class="form_item"
                size='small'
                :clearable='true'
                v-model="params_search.time"
                type="daterange"
                range-separator="-"
                :start-placeholder="$t('Starttime')"
                :end-placeholder="$t('endTime')"
                :default-time="['00:00:00', '23:59:59']"    
            >
            </el-date-picker>
        </div>
        <div>
            <!-- 查询 -->
            <el-button type="primary" size="small" @click="commponentSearch">{{$t('search')}}</el-button>
            <!-- 重置 -->
            <el-button type="default" size="small" @click="commponentReset">{{$t('reset')}}</el-button>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      params_search: {
        name: "",
        status: "",
        time: "",
      },
      options: [  //0 终止 1 未开始 2 进行中 3 结束
        {
          value: "0",
          label: this.$t('Terminated'),
        },
        {
          value: "1",
          label: this.$t('has_not_started'),
        },
        {
          value: "2",
          label: this.$t('Processing_jx'),
        },
        
        {
          value: "3",
          label: this.$t('havemeetingEnd'),
        },
        
      ],
    };
  },
  methods: {
    commponentSearch() {
      let params={
        name:this.params_search.name,
        status:this.params_search.status,
        start_time:"",
        end_time:"",
      }
      if(this.params_search.time){
        params.start_time=this.$moment(this.params_search.time[0]).unix()
        params.end_time=this.$moment(this.params_search.time[1]).unix()
      }
      this.$emit("commponentSearch", params);
    },
    commponentReset() {
      this.params_search = {
        name: "",
        status: "",
        time: "",
      };
    },
  },
};
</script>
<style lang="less" scoped>
.searchBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 14px;
  div:first-child {
    width: 1050px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .form_item {
      margin-left: 12px;
    }
  }
  div:nth-child(2){
    min-width:120px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
}
</style>