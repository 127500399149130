<template>
  <div class="cashBox">
    <cashTop :cashTopValue="cashTopValue" ></cashTop>
    <div style="min-height: 500px;background:#fff">
        <div class="myborder border">
      <!-- 红包管理 -->
      <pageTitle :title="$t('CashRedEnvelope')" class="border" style="margin-top:12px;" :isBorder='true'>
        <template slot="right">
          <!-- 发红包  -->
          <el-button
            v-if="inspectButton('mem:xrm:sdr')"
            type="primary"
            size="small"
            style="margin-right: 12px"
            @click="handleAdd"
            >{{$t('RedEnvelopes')}}</el-button
          >
        </template>
      </pageTitle>
      <div style="margin: 20px 0 12px;">
        <searchBox @commponentSearch="commponentSearch"></searchBox>
      </div>
    </div>
    <div class="tableBlock" style="padding-bottom:15px;">
      <el-table
        :data="tableData"
        style="width: 100%;"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <!-- <el-table-column prop="id" label="ID" width="100"></el-table-column> -->
        <!-- 红包名称 -->
        <el-table-column :label="$t('RedEnvelopeName')" width="210">
          <div slot-scope="scope" class="mainColor cursor textOverflow" :title="scope.row.topic_en | priorFormat(scope.row.topic_zh, LOCALE)"  @click="handleEdit(scope.row)">
            {{ scope.row.topic_en | priorFormat(scope.row.topic_zh, LOCALE) }}
          </div>
        </el-table-column>
        <!-- 类型 1?"拼手气红包":"普通红包" -->
        <el-table-column  :label="$t('Types')" width="180" >
          <div slot-scope="scope">
            {{ scope.row.envelope_type==1?$t('Lucky_Red_Envelope'):$t('Ordinary_red_envelope')}}
          </div>
        </el-table-column>
        <!-- 发放时间 -->
        <el-table-column :label="$t('Issuance_time')"  width="320">
          <div slot-scope="scope">
            {{scope.row.start_time|secondFormat('LLL')}}-
            {{scope.row.end_time|secondFormat('LLL')}}
          </div>
        </el-table-column>
        <!-- 发放状态 -->
        <el-table-column :label="$t('IssuanceStatus')"  width="150">
          <!-- //0 终止 1 未开始 2 进行中 3 结束 -->
          <div slot-scope="scope" :class="{'mainColor':scope.row.myStatus==2}">
            {{ scope.row.myStatus==0?$t('Terminated'):scope.row.myStatus==1?$t('has_not_started'):scope.row.myStatus==2?$t('Processing_jx'):$t('havemeetingEnd')}}
          </div>
        </el-table-column>
        <!-- 金额 -->
        <el-table-column  :label="page_currency"  width="120" >
          <div slot-scope="scope">
              <span class="mainColor" :class="{cursor:inspectButton('mem:xrm:lqd')}" @click="handleDetail(scope.row)">{{
                scope.row.receive_amount|moneyFormat
              }}</span>/{{scope.row.total_amount|moneyFormat}}
          </div>
        </el-table-column>
        <!-- 数量 -->
        <el-table-column  :label="$t('quantity2')" >
          <div slot-scope="scope">
            <span class="mainColor" :class="{cursor:inspectButton('mem:xrm:lqd')}"  @click="handleDetail(scope.row)">{{
                scope.row.receive_count
              }}</span>
             /{{ scope.row.total_num}}
          </div>
        </el-table-column>
        <!-- 操作时间 -->
        <el-table-column  :label="$t('handleTime')" width="190">
          <div slot-scope="scope">
             {{ scope.row.updated_time|secondFormat('LLLL')}}
          </div>
        </el-table-column>
        <el-table-column :label="$t('handle')"  width="100" fixed="right">
          <!-- //myStatus 0已终止 1未开始 2进行中 3 已结束 -->
          <template  slot-scope="scope">
            <alTableBtns
              :btnsList="scope.row.btnsList"
              :btnsIndex="scope.row.$index"
              :rowData="scope.row"
            ></alTableBtns>
            <!-- 领取详情  
            <div v-if="scope.row.myStatus==0 || scope.row.myStatus==3">
              
              <el-button type="text"    v-if="inspectButton('mem:xrm:lqd')" @click="handleDetail(scope.row)">{{$t('Get_details')}}</el-button>
            </div>
            <div v-if="scope.row.myStatus==1">
              编辑 
              <el-button type="text"   v-if="inspectButton('mem:xrm:mdi')" @click="handleEdit(scope.row)" >{{$t('edit')}}</el-button>
              <el-divider direction="vertical" class='mainColor'  v-if="inspectButton('mem:xrm:mdi')"></el-divider>
              删除 
              <el-button   v-if="inspectButton('mem:xrm:del')" type="text" @click="handleDel(scope.row)"  >{{$t('delete')}}</el-button>
            </div>
            <div v-if="scope.row.myStatus==2">
              终止  
              <el-button  v-if="inspectButton('mem:xrm:sto')" type="text" @click="handleStop(scope.row)"  >{{$t('termination2')}}</el-button>
              <el-divider direction="vertical" class='mainColor'  v-if="inspectButton('mem:xrm:sto')"></el-divider>
              领取详情 
              <el-button   v-if="inspectButton('mem:xrm:lqd')" type="text" @click="handleDetail(scope.row)"   >{{$t('Get_details')}}</el-button>
            </div> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
        <el-pagination
          @current-change="handleChange"
          :current-page.sync="currentPage"
          :page-size="10"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
    </div>
    </div>
    <!-- 左边的抽屉 -->
    <el-drawer
      :title="drawerTitle"
      size="700px"
      :visible.sync="drawerShow"
      direction="rtl"
      :before-close="handleClose"
    >
      <drawerCash
        v-if="drawerShow"
        @upLoadSuccess="upLoadSuccess"
        @drawerClose="drawerClose"
        :drawerType='drawerType'
        :drawerDisabled='drawerDisabled'
        :related_id="related_id"
        :drawerValue='drawerValue'
        :currency="cashTopValue.currency"
      ></drawerCash>
    </el-drawer>
  </div>
</template>
<script>
import pageTitle from "~bac/components/common/pageTitle";
import searchBox from "~bac/components/conference/redEnvelopeAdmin/searchBox";
import cashTop from "~bac/components/conference/redEnvelopeAdmin/cashTop";
import drawerCash from "~bac/components/conference/redEnvelopeAdmin/drawerCash";
import alTableBtns from "~/baseComponents/alTableBtns";
export default {
  components: {
    pageTitle,
    searchBox,
    cashTop,
    drawerCash,
    alTableBtns
  },
  data() {
    return {
      related_id: "",
      cashTopValue: {
        receive_count: 0, //  #领取数量 
        receive_amount: 0, // #领取金额
        balance: 0,
        currency:0,
      },
      currentPage:1,
      query_search:{
        start_time:"",
        end_time:"",
        topic:"",
        status:""
      },
      page_currency:'',
      tableHeight: 100,
      tableData: [],
      total: 0,
      loading: false,
      drawerShow: false,
      drawerTitle:"",
      drawerType:"",
      drawerValue:"",
      drawerDisabled:false,
    };
  },
  mounted() {
    this.related_id = this.USER_INFO.company_id
    this.getEnvelopeAaccountInfo();
    this.getList();
  },
  methods: {
    // 获取账户信息
    async getEnvelopeAaccountInfo() {
      let params = {
        related_id: this.related_id,
        account_type: 2, // 账户类型: 1-用户账户，2-公司账户(现金)，3-公司虚拟账户（业务）
      };
      let envelopeAaccountInfo = await this.$store.dispatch(
        "baseConsole/envelopeAaccountInfo",
        params
      );
      if (envelopeAaccountInfo && envelopeAaccountInfo.success) {
        let res = envelopeAaccountInfo.data;
        this.cashTopValue.receive_count = res.receive_count; //  #领取数量
        this.cashTopValue.receive_amount = res.receive_amount; // #领取金额
        this.cashTopValue.balance = res.balance;
        this.cashTopValue.currency = res.currency;

        if(res.currency==1){
          this.page_currency=this.$t('amount')+'(￥)'
        }else{
          this.page_currency=this.$t('amount')+'($)'
        }

      }
    },
    // 获取红包列表
    async getList() {
      let params = {
        related_id: this.related_id,
        meeting_id:this.$store.state.meeting_id,
        account_type: 2, //1-用户账户，2-公司账户(现金)，3-公司虚拟账户（业务）
        start_time:this.query_search.start_time,
        end_time: this.query_search.end_time,
        topic:this.query_search.topic,
        status:this.query_search.status, //0 终止 1 未开始 2 进行中 3 结束
        start:this.currentPage-1,
        limit:10
      };
      this.loading = true;
      let list = await this.$store.dispatch("baseConsole/envelopeList", params);
      if(list && list.success){
        this.tableData = list.data.map(item=>{
             if(item.is_abort==1){ // #是否已终止：0-否，1-是
                item.myStatus=0
              }else{
                let nowTime= this.$moment().unix()
                if(nowTime<item.start_time){
                  item.myStatus=1
                }else if(nowTime>item.end_time){
                  item.myStatus=3  //myStatus 0已终止 1未开始 2进行中 3 已结束
                }else{
                  item.myStatus=2
                }
              }


          item.btnsList = [

              {// 编辑
                text: this.$t("edit"),
                event: "handleEdit",
                otherParams: 1,
                notDisabled: item.myStatus==1,
                auth: "mem:xrm:mdi",
              },
              { //终止
                text: this.$t("termination2"),
                event: "handleStop",
                notDisabled: item.myStatus==2,
                auth: "mem:xrm:sto",
              },
              { // 领取详情
                text: this.$t("Get_details"),
                event: "handleDetail",
                notDisabled:item.myStatus!=1,
                auth: "mem:xrm:lqd",
              },
              {// 删除 
                text: this.$t("delete"),
                event: "handleDel",
                notDisabled: item.myStatus==1,
                auth: "mem:xrm:del",
              },
            ];
          return item
        });
        this.total = list.total;
      }else{
        this.tableData=[]

      }
      
      this.loading = false;
      
    },
    // 子组件的搜索
    commponentSearch(e) {
      this.query_search.start_time=e.start_time
      this.query_search.end_time=e.end_time
      this.query_search.topic=e.name
      this.query_search.status=e.status
      this.currentPage=1
      this.getList()
    },
    // 分页
    handleChange(e) {
      this.currentPage=e
      this.getList()
    },
    // 抽屉关闭之前
    handleClose() {
      this.drawerShow = false;
    },
    // 子组件中的取消
    drawerClose() {
      this.drawerShow = false;
    },
    // 添加红包
    handleAdd(){
      this.drawerDisabled=false
      this.drawerTitle= this.$t('Add_envelope') //"添加现金红包"
      this.drawerType='add'
      this.drawerValue={
        topic_zh:"",
        topic_en:"",
        start_time:"",
        end_time:"",
        envelope_type:"", //红包类型: 1-拼手气红包，2-普通红包
        total_num:"",     //发放总量
        total_amount:"",  //发放总金额
        currency:"1",     //币种: 1-人民币，2-美元
        limit_receive_num:"",//限领次数
        is_abort:"0",     //是否已终止：0-否，1-是
      }
      this.drawerShow = true
    },
    // 编辑红包
    handleEdit(row){
      if(row.myStatus==1 && this.inspectButton('mem:xrm:mdi')){
        this.drawerDisabled=false 
      }else{
        this.drawerDisabled=true
      }
      this.drawerTitle=this.$t('Edit_envelope')//"编辑现金红包"
      this.drawerType='edit'
      this.drawerValue=row
      this.drawerShow = true
    },
    // 领取详情
    handleDetail(row){
      if(!this.inspectButton('mem:xrm:lqd')){
        return
      }
      this.$router.push({
        path: "/console/conference/cash_detail",
        query: {
          parameter: this._encode({
            envelope_id: row.id,
          }),
        },
      });
    },
    // 终止
    handleStop(row){
      if(!this.inspectButton('mem:xrm:sto')){
        return
      }
      let params={
        related_id:this.related_id,
        red_envelope_cash_id:row.id,
        is_abort:1,
        already_received_money:row.receive_amount
      }

      this.$confirm(this.$t('distribution_envelopes'), this.$t('tipsTitle'), {
        confirmButtonText:this.$t('Confirm'),
        cancelButtonText: this.$t('cancel'),
        type: "warning",
      }).then(async () => {
        let upload= await this.$store.dispatch("baseConsole/cash_red_envelope",params);
        if(upload && upload.success){
          this.$message.success(this.$t('Successful_operation'))
        }
        this.getEnvelopeAaccountInfo()
        this.getList()
      }).catch(() => {
                 
      });
    },
    // 删除
    handleDel(row){
      if(!this.inspectButton('mem:xrm:del')){
        return
      }
      let params={
        related_id:this.related_id,
        red_envelope_cash_id:row.id,
        deleted:1
      }

      this.$confirm(this.$t('delete_envelope?'), this.$t('tipsTitle'), {
        confirmButtonText:this.$t('Confirm'),
        cancelButtonText: this.$t('cancel'),
        type: "warning",
      }).then(async () => {
        let upload= await this.$store.dispatch("baseConsole/cash_red_envelope",params);
        if(upload && upload.success){
          this.$message.success(this.$t('Successful_operation'))
        }
        this.getEnvelopeAaccountInfo()
        this.getList()
      }).catch(() => {
                 
      });
    },
    // 抽屉提交成功的函数
    upLoadSuccess(e){
      this.drawerShow = false
      this.getList()
      this.getEnvelopeAaccountInfo()
    }
  },
};
</script>
<style lang="less" scoped>

</style>