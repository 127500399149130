<template>
  <div class="statistics">
    <div class="cash_top">
      <!-- 现金红包 -->
        <h1 class="h1_lable">{{$t('CashRedEnvelope')}}</h1>
        <div class="record" @click="handleJump"  v-if="inspectButton('mem:acr:acr')"> 
          <!-- 账户记录 -->
          <div class="mainColor"> {{$t('accountRecords')}}</div>
        </div>
    </div>
    <div class="cash_bottom">
        <div class="bom_item">
          <!-- 已领取数量 -->
          <span class="lable">{{$t('Quantity_received')}}：</span>
          <span class="num">{{ cashTopValue.receive_count }}</span>
        </div>
         <div class="bom_item">
           <!-- 已领取金额 -->
          <span class="lable">{{$t('Amount_received')}}{{cashTopValue.currency==1?'(￥)':"($)"  }}  ：</span>
          <span class="num">{{ cashTopValue.receive_amount|moneyFormat }}</span>
        </div>
         <div class="bom_item">
           <!-- 发放时间结束后未领取金额将返还至账户余额 -->
          <i class="el-icon-question cursor question lable" :title="$t('time_end_amount')"></i>
          <!-- 账户余额 -->
          <span class="lable">{{$t('AccountBalance ')}}（￥）：</span>
          <span class="num">{{ cashTopValue.balance|moneyFormat }}</span>
          <!-- 提现 -->
          <!-- <span class="btn">{{$t('withdraw')}}</span> -->
        </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    cashTopValue:{
      type:Object,
      default:function(){
        return{
          receive_count: 0,//  #领取数量
          receive_amount: 0, // #领取金额
          balance:0,
        }
      }
    },
  },
  data() {
    return {

    };
  },
  methods:{
    handleJump(){
      if(!this.inspectButton('mem:acr:acr')){
        return
      }
      this.$router.push({
        path:"/console/conference/accountRecords"
      })
    }
  }
};
</script>
<style lang="less" scoped>
.record{
  cursor: pointer;
}
.statistics {
  padding: 32px;
  height: 162px;
  background: #fff;
  margin-bottom: 12px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.19);
}
.cash_top{
  display: flex;
  justify-content:space-between;
  .h1_lable{
    height: 28px;
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
  }
  .mainColor{
    font-size: 14px;
    font-weight: 400;
  }
  .money{
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #027FFF;
    line-height: 16px;
  }

}
.cash_bottom{
  display: flex;
  margin-top: 35px;
  .bom_item{
    padding-right: 100px;
    .lable{
      height: 22px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 22px;
    }
    .num{
      height: 24px;
      font-size: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
    }
    .question{
      cursor: pointer;
    }
    .btn{
      height: 22px;
      font-size: 14px;
      font-weight: 400;
      color: #1890FF;
      line-height: 22px;
      display: inline-block;
      padding-left: 10px;
      cursor: pointer;
    }
  }
}

</style>